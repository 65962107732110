@import './vars'

body
    min-width: $bodyMinWidth
    overflow-x: hidden

.ta-c
    text-align: center!important

.ta-r
    text-align: right!important

.ta-l
    text-align: left!important

.td-n
    text-decoration: none!important

.df
    display: flex

.n-color
    color: $negative!important

.p-color
    color: $positive!important

.e-color
    color: $error!important



@for $i from 1 to 100
    .mt#{$i}
        margin-top: #{$i}px !important
    .mb#{$i}
        margin-bottom: #{$i}px !important
    .ml#{$i}
        margin-left: #{$i}px !important
    .mr#{$i}
        margin-right: #{$i}px !important
    .pt#{$i}
        padding-top: #{$i}px !important
    .pb#{$i}
        padding-bottom: #{$i}px !important
    .pl#{$i}
        padding-left: #{$i}px !important
    .pr#{$i}
        padding-right: #{$i}px !important

    .mtX#{$i}
        margin-top: $i * 10px !important
    .mbX#{$i}
        margin-bottom: $i * 10px !important
    .mlX#{$i}
        margin-left: $i * 10px !important
    .mrX#{$i}
        margin-right: $i * 10px !important
    .ptX#{$i}
        padding-top: $i * 10px !important
    .pbX#{$i}
        padding-bottom: $i * 10px !important
    .plX#{$i}
        padding-left: $i * 10px !important
    .prX#{$i}
        padding-right: $i * 10px !important

@for $i from 1 to 30
    .fz#{$i}
        font-size: #{$i}px !important

    .lh#{$i}
        line-height: #{$i}px !important
