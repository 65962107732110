@import '../../style/vars'

.dark-header
    position: fixed
    width: 100%
    min-width: $bodyMinWidth
    top: 0
    left: 0
    display: flex
    align-items: center
    padding: 0 40px
    z-index: 200
    box-sizing: border-box
    background-color: $black
    &__nav
        display: flex
        align-items: center
        padding: 0 25px
        border-left: 1px solid #353535
        border-right: 1px solid #353535
        flex: 1
        margin: 0 25px
        min-height: 48px
    & > a
        max-width: 120px
        padding: 4px
        position: relative
        display: flex
        border-radius: 10px
        img
            max-width: 100%
            display: block
    &__link
        display: flex
        align-items: center
        background-color: $black
        color: $white
        padding: 0 2px
        height: 100%
        margin-right: 35px
        font-size: 15px
        font-weight: 600
        border-bottom: 3px solid transparent
        transition: 0.3s all ease
        min-height: 72px
        &:hover
            text-decoration: none
            color: $white
            border-bottom-color: #FFDB40
            svg
                path
                    fill: #FFDB40
        &.active
            color: #FFDB40
            pointer-events: none
            svg
                path
                    fill: #FFDB40
        svg
            margin-right: 8px
            transition: 0.3s all ease
            path
                fill: $white
    &__link--dropdown
        padding: 0
        position: relative
        padding: 19px 20px 15px 0
        cursor: pointer
        &::before
            content: ''
            position: absolute
            right: 0
            top: 50%
            width: 10px
            height: 6px
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjQxOCAwLjg1OTAyQzguNjg5IDAuNTkxMDIgOS4xMjcgMC41OTEwMiA5LjM5NiAwLjg1OTAyQzkuNjY2IDEuMTI3MDIgOS42NjggMS41NjAwMiA5LjM5NiAxLjgyODAyTDUuNDg4IDUuNjU4MDJDNS4yMTggNS45MjYwMiA0Ljc4MSA1LjkyNjAyIDQuNTA5IDUuNjU4MDJMMC42MDA5OTkgMS44MjgwMkMwLjMzMDk5OSAxLjU2MTAyIDAuMzMwOTk5IDEuMTI3MDIgMC42MDA5OTkgMC44NTkwMkMwLjg3MTk5OSAwLjU5MTAyIDEuMzEgMC41OTEwMiAxLjU3OSAwLjg1OTAyTDUgNC4wMDAwMkw4LjQxOCAwLjg1OTAyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)
    &__link--hidden
        display: none
    &__right
        margin-left: auto
        .header__link
            margin: 0
        .dark-header__link--dropdown
            padding-top: 0
            padding-bottom: 0
            min-height: 72px
            display: flex
            align-items: center
    &__profile
        width: 40px
        height: 40px
        background-color: $yellow
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        font-size: 18px
        font-weight: 600
