@import './vars'
    
.link
    font-size: inherit
    color: $black
    text-decoration: none
    cursor: pointer
    &:hover
        text-decoration: underline
    &.link--underline
        text-decoration: underline
        &:hover
            text-decoration: none
