.ant-pagination-item-active,
.ant-pagination-item-active:hover,
.ant-pagination-item:hover
    border-color: #FFCF01
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover,
.ant-pagination-item a:hover
    color: #FFCF01

.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link
    border-color: #FFCF01
    color: #FFCF01
