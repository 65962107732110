@import './vars'

#datepicker-root-portal
    z-index: 99999
    position: relative

.react-datepicker__triangle
    display: none

.react-datepicker__header
    background-color: transparent

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before
    border-color: black
    border-style: solid
    border-width: 2px 2px 0 0
    transition: 0.3s all ease

.react-datepicker__header
    padding: 22px 0 0
    border: none

.react-datepicker__month
    margin: 11px 20px

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
    width: 30px

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header
    font-size: 14px

.react-datepicker__navigation--next
    right: 19px
    top: 15px

.react-datepicker__navigation--previous
    left: 19px
    top: 15px

.react-datepicker__month-container
    float: none

.react-datepicker__day-name
    color: #AAAAAA
    font-size: 10px

.react-datepicker__day-names
    margin-top: 15px

.react-datepicker__day
    font-size: 14px

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range
    background-color: $datepickerActive

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover
    background-color: $datepickerActive

.react-datepicker__day--keyboard-selected
    background-color: $datepickerActive
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)
    background-color: rgba($datepickerActive, 0.5)

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today
    font-weight: normal

.react-datepicker__header--time
    display: none

.datepicker-range
    &__container
        display: flex
        align-items: center
        justify-content: space-between
    &__input
        border: 1px solid $black
        border-radius: 5px
        position: relative
        &::before
            position: absolute
            right: 10px
            top: 50%
            margin-top: -2px
            width: 0
            height: 0
            border-width: 5px 5px 0 5px
            border-color: $black transparent transparent transparent
            border-style: solid
            content: ''
        input
            border: none
            padding: 7px 30px 7px 10px
            font-size: 14px
            background-color: transparent
            width: inherit
    &__devider
        width: 20px
        height: 1px
        background-color: $black
        margin: 0 5px
