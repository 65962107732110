$black: black
$white: white
$error: #FF000F
$yellow: #FFCF01
$yellowShadowColor: #FFF6CE
$grayBg: #F6F6F6
$success: #38B77A
$bodyMinWidth: 1400px
$thGray: #7B7B7B
$bdGrayColor: #E1E1E1
$darkGrayColor: #484848
$negative: #D11A2A
$positive: #38B77A
$datepickerActive: #079D55