@import '../../style/vars'
    
.dark-layout,
.layout
    padding-top: 119px
    box-sizing: border-box
    background-color: #F8F8F8
    min-height: 100vh
    &__content
        margin-left: 40px
        margin-right: 40px
        box-sizing: border-box
    &__title
        font-size: 22px
        margin-bottom: 40px