@import './vars'

.range-select
    &__control
        border-color: $black!important
        border-radius: 5px!important
        box-shadow: none!important
        height: 30px!important
        min-height: 30px!important
        &--is-focused
            border-color: $black!important
    &__indicator-separator
        display: none
    &__indicator
        position: relative
        padding: 12px!important
        &::before
            content: ""
            position: absolute
            left: 50%
            top: 50%
            margin-top: -2px
            margin-left: -5px
            width: 0
            height: 0
            border-width: 5px 5px 0 5px
            border-color: $black transparent transparent transparent
            border-style: solid
        svg
            display: none
    &__single-value
        font-size: 14px
    &__placeholder
        font-size: 14px
    &__option
        font-size: 14px!important
        &--is-focused
            background-color: #efefef!important
        &--is-selected
            background-color: $black!important
    &__menu-portal
        z-index: 1000!important

.multi-select
    &__control
        border-color: #fff!important
        border-radius: 5px!important
        box-shadow: none!important
        width: 100%
        // height: 30px!important
        min-height: auto!important
        &--is-focused
            // border-color: $black!important
    &__indicator-separator
        display: none
    &__menu-portal
        z-index: 1000!important
    &__clear-indicator
        display: none !important
    &__indicator
        position: relative
        padding: 5px!important
        &::before
            content: ""
            position: absolute
            left: 50%
            top: 50%
            margin-top: -2px
            margin-left: -5px
            width: 0
            height: 0
            border-width: 5px 5px 0 5px
            border-color: $black transparent transparent transparent
            border-style: solid
    &__option
        font-size: 14px!important
        &--is-focused
            background-color: #efefef!important
        &--is-selected
            background-color: $black!important
    &__multi-value__label
        padding: 0 3px!important
    &__multi-value__remove
        padding-left: 0!important
        padding-right: 0!important
    &__multi-value__label
        overflow: auto!important
        white-space: normal!important
        line-height: 1.2